
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/equipmenttest";

@Options({})
export default class extends Vue {
  private active = 0;
  private xlptdetail = { deviceNo: "" };
  private listData: any[] = [];
  private towerDetail = {
    unloadingInfoDto: "",
    recordTimeDto: "",
  };
  private searchForm = {
    level: null,
    deviceTime: null,
    deviceNo: "",
    pageSize: 10,
    pageNum: 1,
    total: 0,
  };

  mounted() {
    this.xlptdetail = this.$store.state.project.xlptdetail;
    this.searchForm.deviceNo = this.xlptdetail.deviceNo;
    api
      .detail({
        deviceNo: this.xlptdetail.deviceNo,
        dirId: this.$store.state.project.projectinfoDetail.id,
      })
      .then((res: any) => {
        this.towerDetail.unloadingInfoDto = res.data.unloadingInfoDto;
        this.towerDetail.recordTimeDto = res.data.recordTimeDto;
      });
    api.unloadingAlertRecordPage(this.searchForm).then((res: any) => {
      this.listData = res.data.records;
    });
  }
  morejianceData() {
    this.$router.push({
      path: "/app/xlptjiancedetail",
      query: { id: this.xlptdetail.deviceNo },
    });
  }
  moreGaojingData() {
    this.$router.push({
      path: "/app/xlptgaojingdetail",
      query: { id: this.xlptdetail.deviceNo },
    });
  }
}
